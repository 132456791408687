<template>
  <el-main>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="用户昵称：">
        <el-input size="small" placeholder="请输入用户昵称" v-model="searchForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="手机号码：">
        <el-input size="small" placeholder="请输入手机号码" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="用户身份：">
        <el-select size="small" v-model="searchForm.role_type" clearable>
          <el-option v-for="item in role_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建日期：">
        <el-date-picker
          size="small"
          v-model="searchForm.create_time"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <!-- <el-button size="small">导出</el-button> -->
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>

    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="用户信息" min-width="150">
        <template v-slot="{ row }">
          <div class="userInfo">
            <el-image :src="row.avatar || 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png'"></el-image>
            <div class="flex1">{{ row.nickname }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_order_amount" label="累计消费金额" align="center" min-width="120"></el-table-column>
      <el-table-column prop="total_order_count" label="累计订单数" align="center" min-width="120"></el-table-column>
      <el-table-column prop="role_type" label="用户身份" align="center">
        <template v-slot="{ row }">{{ row.role_type | role_type_text(role_type) }}</template>
      </el-table-column>
      <el-table-column prop="card_item" label="卡项" align="center"></el-table-column>
      <el-table-column prop="card_count" label="卡项次数" align="center" min-width="120">
        <template v-slot="{ row }">
          <div class="row">
            <div style="margin-left: 40%">{{ row.card_count }}</div>
            <el-popover :append-to-body="false" width="200" placement="top-start" trigger="hover" v-if="row.card_item_list.length > 0">
              <div style="color: #409eff" v-for="item in row.card_item_list" :key="item.id">{{ item.card_info.card_item_name }}： {{ item.card_usable_sum }}次</div>
              <i class="el-icon-warning icon" slot="reference"></i>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center" width="180">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column prop="last_login_time" label="上次登录时间" align="center" width="180">
        <template v-slot="{ row }">{{ row.last_login_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="toDetail(scope.row.id)" type="text" size="small">详情</el-button>
          <!-- <el-button type="text" size="small">冻结</el-button> -->
          <el-button type="text" size="small" @click="delItem(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      interval: '',
      key: new Date(),

      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        create_time: '',
        role_type: '',
      },
      total_number: 0,
      role_type: [
        {
          val: '1',
          label: '普通用户',
        },
        {
          val: '2',
          label: '推广员',
        },
        {
          val: '3',
          label: '创业股东',
        },
      ],
    };
  },
  filters: {
    getDateformat(val) {
      if (val) {
        return getDateformat(val);
      } else {
        return '--';
      }
    },
    intervalLabel(val) {
      switch (val) {
        case 15:
          return '15分钟';
          break;
        case 30:
          return '30分钟';
          break;
        default:
          return val / 60 + '小时';
          break;
      }
    },
    role_type_text(val, role_type) {
      return role_type.find(i => i.val == val).label;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        service_name: '',
        service_phone: '',
        create_time: '',
      };
      this.getList();
    },
    getList() {
      let data = Object.assign({}, this.searchForm);
      if (data.create_time) {
        data.create_time[0] = data.create_time[0] / 1000;
        data.create_time[1] = data.create_time[1] / 1000;
      }
      this.$axios.post(this.$api.beauty.userList, data).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total_number;
          this.key = new Date();
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toDetail(id) {
      this.$router.push({ path: '/user/beautyUserDetail', query: { id } });
    },
    delItem(id) {
      this.$confirm('确定要删除此用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post(this.$api.beauty.delUser, { id }).then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.el-table {
  .userInfo {
    display: flex;
    align-items: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.row {
  display: flex;
  align-items: center;

  .icon {
    font-size: 18px;
    margin-left: 10px;
    color: #409eff;
    margin-top: 2px;
  }
}
</style>
